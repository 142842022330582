
import moment from "moment";
import {
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted,
  unref,
  computed,
} from "vue";
import { loginApi } from "@/api/login";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import tools from "@/utils/tools";
import type { FormInstance } from "element-plus";
import { ElMessage } from "element-plus";
// import { updateMenuList } from "@/components/nav/component/config/deviceInfo";
import languageChange from "@/components/whatsapp/languageChange.vue";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";
import { progressbarWs } from "@/api/login";
import Cookies from 'js-cookie';
export default defineComponent({
  name: "Login",
  components: {
    languageChange,
  },
  setup(prop, context) {
    let router = useRouter();
    let store = useStore();
    const i18n = useI18n();

    const ruleFormRef = ref<FormInstance>();
    const senMsg = 1;

    const formData = reactive({
      UserName: "",
      UserPassword: "",
      EmailCode: "",
      signInCount: 0,
      isShowPassword: false,
      isOk: true,
      flag: true,
      code: "",
      input1: false,
      inputPassword: false,
      inputUserName: false,
      // btnText: "Sign in",
      btnText: `$t('password')`,
      isSignIn: true,
      disable: false,
    });

    const langs = computed(() => store.state.currentLang);

    watch(
      () => [formData.UserName, formData.UserPassword, langs.value],
      (newValue, oldVaule) => {
        if (formData.UserName && formData.UserPassword) {
          formData.isOk = true;
          formData.disable = true;
        } else {
          formData.isOk = false;
          // formData.disable = true;
        }
        // if (formData.UserName && formData.UserPassword) {
        //   formData.isOk = true;
        // } else if (newValue[2] !== oldVaule[2]) {
        //   nextTick(() => {
        //     const formRef: any = unref(ruleFormRef);
        //     // formRef.resetField("UserName");
        //     if (!formRef) return;
        //     if (newValue[0] == "" || newValue[1] == "") {
        //       setTimeout(function () {
        //         formRef.clearValidate();
        //       }, 30);
        //     }
        //     // ruleFormRef.clearValidate();
        //     // setTimeout(function () {
        //     //   formRef.resetFields();
        //     // }, 30);
        //   });
        // } else {
        //   formData.isOk = false;
        // }
      }
    );

    let homePage = () => {
      if (window.location.origin.includes('parental')) {
        window.open("https://www.parentalguard.com/", "_blank");
      } else {
        window.open("https://www.famikeep.com/", "_blank");
      }
    };

    onMounted(() => {
      if (sessionStorage.getItem("resetTempEmail")) {
        formData.UserName = sessionStorage.getItem("resetTempEmail") || "0";
        formData.UserPassword =
          sessionStorage.getItem("resetTempPassword") || "0";
        sessionStorage.removeItem("resetTempEmail");
        sessionStorage.removeItem("resetTempPassword");
      }
      checkSignErrCount();
      // 如果是登录过期
      if (store.state.isLoginExpired) {
        formData.UserName = JSON.parse(localStorage.getItem("email") || "0");
        formData.UserPassword = "";
      }
    });

    const emailRule = computed(() => {
      const rule = reactive({
        UserName: [
          { validator: validateUserNameASCII, trigger: "blur" },
          {
            required: true,
            message: i18n.t("pleaseEnterEmailAddress"),
            trigger: "blur",
          },
          {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^\S{1,100}$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
        ],
        UserPassword: [
          { validator: validateUserPasswordASCII, trigger: "blur" },
          {
            required: true,
            message: i18n.t("pleaseEnterPassword"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#_~?&^]{8,20}$/,
            message: i18n.t("passwordMmustBe8"),
            trigger: "blur",
          },
        ],
        EmailCode: [
          {
            required: true,
            message: i18n.t("pleaseEnterVerifiCode"),
            trigger: "blur",
          },
          { validator: validateCode, trigger: "blur" },
        ],
      });
      return rule;
    });

    let checkSignErrCount = async () => {
      formData.signInCount = Number(
        window.localStorage.getItem("signInErrCount")
          ? window.localStorage.getItem("signInErrCount")
          : 0
      );
      var signInErrData = window.localStorage.getItem("signInErrData");
      if (signInErrData != moment().format("YYYY-MM-DD")) {
        localStorage.setItem("signInErrCount", "0");
        localStorage.setItem("signInErrData", moment().format("YYYY-MM-DD"));
        formData.signInCount = 0;
      } else {
        // 检测如果输入错误超过三次则无法登录
        // if (this.signInCount >= 3) {
        //   setTimeout(() => {
        //     this.isOk = false;
        //     this.refreshCode();
        //   }, 500);
        // }
      }
    };

    const validateCode = (rule: any, value: any, callback: any) => {
      if (value != formData.code) {
        callback(new Error("Wrong verification code"));
      } else {
        callback();
      }
    };
    const validateUserNameASCII = (
      rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.UserName = value.replace(re, "");
      callback();
    };
    const validateUserPasswordASCII = (
      rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.UserPassword = value.replace(re, "");
      callback();
    };

    let UserNameTrim = async () => {
      formData.UserName = tools.LTrim(tools.RTrim(formData.UserName));
    };

    let UserPasswordTrim = async () => {
      formData.UserPassword = tools.LTrim(tools.RTrim(formData.UserPassword));
    };

    // const formRef: any = unref(registerFormRef);
    let checkPassword = () => {
      // unref 如果参数是一个 ref 则返回它的 value，否则返回参数本身。
      const formRef: any = unref(ruleFormRef);
      formRef.validateField("UserPassword", (valid: boolean) => {
        if (valid) {
          formData.inputPassword = true;
          return true;
        } else {
          formData.inputPassword = false;
          return false;
        }
      });
    };

    let checkUserName = () => {
      // unref 如果参数是一个 ref 则返回它的 value，否则返回参数本身。
      const formRef: any = unref(ruleFormRef);
      formRef.validateField("UserName", (valid: boolean) => {
        if (valid) {
          formData.inputUserName = true;
          return true;
        } else {
          formData.inputUserName = false;
          return false;
        }
      });
    };

    let goRegister = async () => {
      router.push({ path: "/signup" });
    };

    let goReset = async () => {
      router.push({ path: "/forgotpassword" });
    };

    // const refreshTab = () => {
    //   store.dispatch('getUserInfo')
    //   store.dispatch('getDeviceInfo')
    //   store.dispatch('updateMenuList')
    // }

    let signIn = async () => {
      store.state.loginTime = new Date();
      checkPassword();
      checkUserName();
      if (!formData.isOk) {
        return;
      }
      if (!formData.inputPassword) {
        return;
      }
      if (!formData.inputUserName) {
        return;
      }
      let params = {
        email: formData.UserName,
        password: formData.UserPassword,
      };
      if (formData.disable === true) {
        // formData.disable = true;
        // formData.btnText = "Logging in";
        formData.isSignIn = false;
        // 点击登录按钮后统计一次
        tools.UserEvent("Login_Button", "None");
        loginApi(params)
          .then(async (res) => {
            if (res.data.code == "200") {
              // console.log(res.data, 'res.data')

              let data = new Date().getTime();
              // store.state.loginTime = timeRes - store.state.loginTime;
              router.push({ path: "/home" });
              localStorage.setItem("token", res.data.data.token);
              // test用
              localStorage.setItem("email", res.data.data.email);
              // 登录密钥
              localStorage.setItem("authorization", res.data.data.token);
              Cookies.set('isLogin', "true", { expires: 30 });
              console.log("res.data.authorization:", res.data.data.token);
              // Cookies.set('authorization', res.data.data.token, { expires: 30 });
              store.commit('SET_Authorization', res.data.data.token);
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              localStorage.setItem("user_id", res.data.data.user_id);

              // 给予cookie值
              // tools.setCookie("email", res.data.data.email, 1);
              // tools.setCookie("token", res.data.data.token, 1);

              // 判断当前登录的用户账号是否和上一次一样，一样就保持原来的点击列表，否则清空
              // if (localStorage.getItem("identity")) {
              //   if (
              //     res.data.data.user_id !== localStorage.getItem("identity")
              //   ) {
              //     store.commit("setClickedArray", []);
              //   }
              // }
              localStorage.setItem("identity", res.data.data.user_id);

              // 新接口返回的数据没有userInfo字段
              // 但是登录的时候有需要拿到数据，之前的将字段的id以及email都封装在userInfo中
              // 所以这里要都拿到数据就直接将所有数据都传递过去，因为反正是同层级的，之后要用哪个就直接取就好了
              tools.UserEvent("Login_Success", "None");
              tools.setCookie("sssLLL", 1, 1);

              // 登录之后立马调接口获取当前的价格
              // console.log("----------------", langs.value);
              // 发送网络请求，携带langs.value当前语言
              // 获取当前价格存在store里面，在每个调用登录接口的地方都要发送这个获取价格的网络请求
              // 并且在监听切换多语言时也需要发送该网络请求更新价格

              store
                .dispatch("getDeviceInfo")
                .then(async () => {
                  if (
                    store.state.deviceInfo &&
                    store.state.deviceInfo.length > 0
                  ) {
                    if (store.state.deviceInfo[0].id) {
                      store.commit(
                        "setCurrentDeviceId",
                        store.state.deviceInfo[0].id
                      );
                      store.commit("setCurrentDeviceInfo", store.state.deviceInfo[0]);
                      store.commit('setDeviceType', 0);
                    } else {
                      store.commit('setCurrentAppleId', store.state.deviceInfo[0].apple_id)
                      store.commit(
                        "setCurrentDeviceId",
                        store.state.deviceInfo[0].devices[0].id
                      );
                      store.commit("setCurrentDeviceInfo", { ...store.state.deviceInfo[0].devices[0], is_expired: store.state.deviceInfo[0].is_expired, expire_at: store.state.deviceInfo[0].expired_at });
                      store.commit('setDeviceType', 1);
                    }
                  }

                  await store.dispatch("getUserInfo").catch((err) => {
                    // debugger
                    // console.log(err, 'getUserInfoerr')
                    // throw new Error('error~~~')
                    router.push("/login");
                    throw new Error("error~~~");
                    // return
                  });
                  // console.log('66666666');

                  if (!store.state.subscripts.expires_at) {
                    store.commit("setDefaultMenu", null);
                    router.push({ path: "/addDevice" });
                  } else if (
                    !store.state.deviceInfo.length &&
                    store.state.subscripts.expires_at
                  ) {
                    if (
                      store.state.subscripts.expires_at &&
                      data / 1000 > store.state.subscripts.expires_at
                    ) {
                      store.commit("setExpired", true);
                      store.commit("setDefaultMenu", null);
                      router.push({ path: "/addDevice" });
                    } else {
                      store.commit("setDefaultMenu", null);
                      router.push({ path: "/guide" });
                      store.commit("setCurrentDeviceId", "Device 1");
                      store.commit("setCurrentDeviceInfo", {
                        name: "leftTab.unboundName",
                        id: "Device 1",
                        model: "leftTab.unbound",
                      });
                    }
                    store.commit("setBoundImg", true);
                  } else {
                    router.push({ path: "/home" });
                    store.commit("setDefaultMenu", "home");
                    if (
                      store.state.subscripts.expires_at &&
                      data / 1000 > store.state.subscripts.expires_at
                    ) {
                      store.commit("setExpired", true);
                    }
                  }

                  // 这里的斜杠会被转化成转义运算符
                  let total =
                    store.state.deviceInfo.length +
                    store.state.subscripts.effective_device_num;

                  if (!store.state.subscripts.effective_device_num) {
                    const res11 = store.state.deviceInfo.length + "/" + "0";
                    event("Binddevice_LoginSuccess", {
                      event_category: "AddDevice",
                      event_label: res11,
                    });
                  } else {
                    const res11 = store.state.deviceInfo.length + "/" + total;
                    event("Binddevice_LoginSuccess", {
                      event_category: "AddDevice",
                      event_label: res11,
                    });
                  }
                  // // 调取设备信息
                  store.dispatch("updateMenuList");
                  ElMessage.success(i18n.t("loginSucceeded"));
                })
                .catch((err) => {
                  // console.log(err, 'catch err')
                  router.push("/login");
                  throw new Error("error~~~");
                });

              let code = localStorage.getItem("authorization");
              if (localStorage.getItem("authorization")) {
                progressbarWs(code, 1);
              }
              // if (localStorage.getItem('oos') == '1') {
              //   refreshTab()
              //   localStorage.setItem('oos', '3')
              // } else if (localStorage.getItem('oos') == '2') {
              //   refreshTab()
              //   localStorage.setItem('oos', '3')
              // }

              // 调取设备信息
              // await store.dispatch('updateMenuList')
            } else {
              // context.emit("loginResponseError", res.data.code);
              tools.UserEvent("Login_Fail", res.data.code);
              formData.disable = true;
            }

            switch (res.data.code) {
              case 400200:
                ElMessage.error(i18n.t("invalidPasswordFormat"));
                formData.disable = true;
                break;
              case 409101:
                ElMessage.error(i18n.t("theEmailHasRegistered"));
                formData.disable = true;
                break;
              case 400302:
                ElMessage.error(i18n.t("incorrectVerifiCode"));
                formData.disable = true;
                break;
              case 400301:
                ElMessage.error(i18n.t("verificationCodeGetAgain"));
                formData.disable = true;
                break;
              case 400205:
                ElMessage.error(i18n.t("erificationCodeMatch"));
                formData.disable = true;
                break;
              case 404001:
                ElMessage.error(i18n.t("emailRegistered"));
                formData.disable = true;
                break;
              case 400201:
                ElMessage.error(i18n.t("incorrectPasswordOrEmail"));
                formData.disable = true;
                break;
              case 400206:
                ElMessage.error(i18n.t("passwordNotold"));
                formData.disable = true;
                break;
              case 400103:
                ElMessage.error(i18n.t("pleaseTryAgainLater"));
                formData.disable = true;
                break;
              case 401534:
                ElMessage.error(i18n.t("wrongPasswordTime"));
                formData.disable = true;
                break;
              case 401534:
                ElMessage.error(i18n.t("wrongPasswordTime"));
                formData.disable = false;
                break;
              default:
            }

            // 错误提示
            // if (res.data.code == "400201") {
            //   ElMessage.error(
            //     "Email or password incorrect, please enter again"
            //   );
            //   formData.disable = false;
            //   formData.btnText = "Sign in";
            // } else if (res.data.code == "401534") {
            //   ElMessage({
            //     dangerouslyUseHTMLString: true,
            //     message:
            //       "You have entered the wrong password 5 times. Your account is temporarily locked <br/>" +
            //       "and cannot be logged in. Please try 10 mins later.",
            //     type: "error",
            //   });
            //   formData.disable = false;
            //   formData.btnText = "Sign in";
            // } else if (res.data.code == "404001") {
            //   ElMessage.error(i18n.t("emailRegistered"));
            //   formData.disable = false;
            //   formData.btnText = "Sign in";
            // } else {
            //   formData.disable = false;
            //   formData.btnText = "Sign in";
            // }

            let num = window.localStorage.getItem("signInErrCount") || "";
            localStorage.setItem("signInErrCount", num);
            // localStorage.setItem("signInErrCount", ++num);
            localStorage.setItem(
              "signInErrData",
              moment().format("YYYY-MM-DD")
            );
            formData.isSignIn = true;
          })
          .catch((err) => {
            // console.log("登录失败", err);
            // ElMessage.error("登录失败");
            tools.UserEvent("Login_Fail", "None");

            formData.disable = true;
            // formData.btnText = "Sign in";
            formData.isSignIn = true;
            //message.error(err)
          });
      }
    };

    return {
      store,
      signIn,
      formData,
      UserNameTrim,
      UserPasswordTrim,
      goRegister,
      goReset,
      ruleFormRef,
      checkPassword,
      checkUserName,
      senMsg,
      emailRule,
      homePage,
    };
  },
});
